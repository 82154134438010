<template lang="pug">
  b-row.mb-5
    b-col

      b-card.manager-organizations-list.card-white.card-no-gutters.shadow(no-body)
        b-card-header.bg-transparent.border-0
          wc-tables-header(
            :title="$t('activerecord.models.organization.other')"
            :action-new='policies.new'
            :route-new="{ name: 'manager-organizations-new' }"
            @click-data-clean-fetch='dataCleanFetch'
            @click-data-fetch='dataFetch')

        b-card-body
          b-row
            b-col
              wc-tables-filter(
                :filter.sync='filter'
                :p-page.sync='pagination.pPage'
                :p-page-options='pagination.pPageOptions'
                @submit='dataFetch'
                @submit-clear='filterClear')

          b-row
            b-col
              transition(name='fade')
                wc-tables-wrapper-default(
                  :ref='wns'
                  :items='items'
                  :fields='fields'
                  :sort-by.sync='sorting.sortBy'
                  :sort-desc.sync='sorting.sortDesc'
                  :is-loading='isLoading'
                  :action-delete='actionDestroy'
                  @row-clicked='rowClicked'
                  @row-delete='rowDelete'
                  @sort-changed='sortingChanged'
                  attr-active)
                  template(v-slot:cell(attributes.identity_username)='data')
                    | {{ $getDeep(included.find(x => x.type === 'identity' && x.id === $getDeep(data.item, 'relationships.identity.data.id')), 'attributes.username') }}

                  template(v-slot:cell(attributes.state)='data')
                    b-badge.state.p-2(
                      :variant="$wc.conf.states.organization.variants[data.item.attributes.state]"
                      pill)
                      i.fa-fw(:class="$wc.conf.states.organization.icons[data.item.attributes.state]")
                      |  {{ $t('organization.states.' + data.item.attributes.state) }}

                  template(v-slot:cell(actions)='data')
                    b-dropdown.form-buttons-actions-item-menu(
                      toggle-tag='div'
                      toggle-class='p-0 text-muted shadow-none'
                      variant='transparent'
                      menu-class='border-0 p-0'
                      size='sm'
                      right
                      no-caret)
                      template(#button-content)
                        i.fal.fa-fw.fa-2x.fa-ellipsis-h.text-muted
                      b-dropdown-item(
                        v-if="data.item.attributes.policies.approved"
                        link-class='py-3'
                        :disabled='data.item.isLoading'
                        @click.stop.prevent="itemTransition(data.item, 'approved', data.index)")
                        i.fa-fw.text-muted(:class="$wc.conf.states.organization.icons.approved")
                        |  {{ $t('organization.menus.to_approve') }}
                      b-dropdown-item(
                        v-if="data.item.attributes.policies.rejected"
                        link-class='py-3'
                        :disabled='data.item.isLoading'
                        @click.stop.prevent="itemTransition(data.item, 'rejected', data.index)")
                        i.fa-fw.text-muted(:class="$wc.conf.states.organization.icons.rejected")
                        |  {{ $t('organization.menus.to_reject') }}
                      b-dropdown-item(
                        v-if="data.item.attributes.policies.draft"
                        link-class='py-3'
                        :disabled='data.item.isLoading'
                        @click.stop.prevent="itemTransition(data.item, 'draft', data.index)")
                        i.fa-fw.text-muted(:class="$wc.conf.states.organization.icons.draft")
                        |  {{ $t('organization.menus.to_redraft') }}
                      b-dropdown-item(
                        v-if="data.item.attributes.policies.deactivated"
                        link-class='py-3'
                        :disabled='data.item.isLoading'
                        @click.stop.prevent="itemTransition(data.item, 'deactivated', data.index)")
                        i.fa-fw.text-muted(:class="$wc.conf.states.organization.icons.deactivated")
                        |  {{ $t('organization.menus.to_deactivate') }}

        b-card-footer.border-0.bg-white
          wc-tables-footer(
            :total-items='pagination.totalItems'
            :p-page='pagination.pPage'
            :c-page.sync='pagination.cPage')

        loading(
          :active.sync='isLoading'
          :can-cancel-esc='true'
          :on-cancel='onCancel')
</template>

<script>
import Table from '@common/table'
import apiOrganizations from '@services/api/manager/organizations'

export default {
  name: 'manager-organizations-list',
  mixins: [Table],
  props: {
    wns: {
      type: String,
      default: 'org',
    },
  },
  methods: {
    apiParams(item = {}) {
      return {
        list: [{}, this.sortingParams],
        transitionApproved: [{ id: this.$getDeep(item, 'attributes.sid'), transition: 'approved' }],
        transitionRejected: [{ id: this.$getDeep(item, 'attributes.sid'), transition: 'rejected' }],
        transitionDraft: [{ id: this.$getDeep(item, 'attributes.sid'), transition: 'draft' }],
        transitionDeactivated: [
          { id: this.$getDeep(item, 'attributes.sid'), transition: 'deactivated' },
        ],
        destroy: [{ id: this.$getDeep(item, 'attributes.sid') }],
      }
    },
    routeParams(item) {
      return {
        edit: [
          { name: 'manager-organizations-edit', params: { organization_id: item.attributes.sid } },
        ],
      }
    },
    itemTransition(item, transition, index) {
      if (!item.attributes.policies[transition]) return
      this.$mergeDeep(this.items[index], true, 'isLoading')
      this.apiRequest = this.apiRequestBuilder(
        'transition',
        item,
        `transition${transition.charAt(0).toUpperCase()}${transition.slice(1)}`
      )
      this.apiRequest.promise
        .then((response) => {
          this.$mergeDeep(this.items[index], response.data)
          if (!this.skipNotifications) this.notifyDispatch(response)
          this.$mergeDeep(this.items[index], false, 'isLoading')
          this.apiCallback('item-transition-success')
          return true
        })
        .catch((response) => {
          if (!this.skipNotifications) this.notifyDispatch(response)
          this.$mergeDeep(this.items[index], false, 'isLoading')
          this.apiCallback('item-transition-error')
        })
      this.apiCallback('item-transition')
    },
  },
  data() {
    return {
      apiBase: apiOrganizations,
      actionRowClicked: 'edit',
      fields: [
        {
          key: 'id',
          label: this.$t('activerecord.attributes.shared.id'),
          sortable: true,
          attr: 'organizations.id',
          class: 'dots-100 h-100',
          noClick: true,
        },
        {
          key: 'attributes.name',
          label: this.$t('activerecord.attributes.shared.name'),
          sortable: true,
          attr: 'organizations.name',
        },
        {
          key: 'attributes.identity_username',
          label: this.$t('activerecord.models.identity.one'),
          sortable: true,
          attr: 'organizations.username',
        },
        {
          key: 'attributes.state',
          label: this.$t('activerecord.attributes.shared.state'),
          sortable: true,
          attr: 'organizations.state',
        },
        {
          key: 'attributes.discarded_at',
          label: this.$t('activerecord.attributes.shared.discarded_at'),
          sortable: true,
          attr: 'organizations.discarded_at',
        },
        {
          key: 'attributes.updated_at',
          label: this.$t('activerecord.attributes.shared.updated_at'),
          sortable: true,
          attr: 'organizations.updated_at',
        },
        {
          key: 'attributes.created_at',
          label: this.$t('activerecord.attributes.shared.created_at'),
          sortable: true,
          attr: 'organizations.created_at',
        },
        {
          key: 'actions',
          label: this.$t('shared.menus.actions'),
          class: 'text-right',
        },
        {
          key: 'delete',
          label: this.$t('shared.actions.delete'),
          class: 'text-right',
        },
      ],
    }
  },
}
</script>
